import "./routes.css";

import { IonContent, IonPage } from "@ionic/react";

import Footer2 from "../common/footer/Footer2";
import Header from "../common/header/Header";
import HeroSection from "../common/HeroSection/HeroSection";
import KuposHeader from "../../components/KuposHeader";
import { PBText } from "../../components";
import React, { useState } from "react";
import { connectData } from "../../redux";
// import HeroSection from "../../common/HeroSection/HeroSection";
import getIcons from "../functions/getIcons/getIcons";

const RouteBox = ({ from, to, schedule, nightSchedule, routeImage, box }) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className="route-box  font13">
      <p>
        <span style={{ color: box === "box-one" ? '#0152a6' : '#FFC72C' }}>Desde <strong>{from}</strong></span> <span style={{ color: box === "box-one" ? '#FFC72C' : '#0152a6' }}>hacia <strong>{to}</strong></span>
      </p>

      <p><strong>Lunes – Domingo:</strong> {schedule}</p>
      {nightSchedule && <p style={{ marginTop: -10 }}><strong>Salidas nocturnas:</strong> {nightSchedule}</p>}

      {showDetails && (
        <div className="route-image-container">
          <img src={routeImage} alt="Ruta" className="route-map" />
        </div>
      )}

      <div className="toggle-button" onClick={() => setShowDetails(!showDetails)}>
        {showDetails ? (
          <span>
            Cerrar ruta <img src="/assets/svgs/up-white-arrow.svg" alt="Arrow Up" width="12" height="12" style={{verticalAlign:"middle",marginLeft:2}} />
          </span>
        ) : (
          <span>
            Ver ruta <img src="/assets/svgs/down-white-arrow.svg" alt="Arrow Down" width="12" height="12" style={{verticalAlign:"middle",marginLeft:2}}/>
          </span>
        )}
      </div>
    </div>
  );
};


const Theme11TerminalSur = ({ operator, history }) => {
		return (
			<IonPage>
   <IonContent>
			<KuposHeader
            // data={props.data}
												title={"Ruta Terminal Sur"}
            boldTitle={"-Aeropuerto"}
            handleHomeSubmit={() => history.push("/")}
            handleBackSubmit={() => history.goBack()}
            backgroundColor={"white"}
          />
   
      <div className="new-container route-container mb10 mt10" style={{ padding: 20 }}>
							<div className="image-route-container">
						     	<img src="/assets/centropuerto/routes/terminal.png"/>
												{/* <img src="/assets/centropuerto/routes/terminal-sur.png" className="route-image"/> */}
							</div>

              <div style={{ paddingTop: "10px" }}>
            <RouteBox
              from="Terminal Sur"
              to="Aeropuerto"
              schedule="06:00 - 22:00 hrs"
              routeImage="/assets/centropuerto/routes/terminal-box-one.png"
              box="box-one"
            />
          </div>
          <div style={{ paddingBottom: "10px", paddingTop: "5px" }}>
            <RouteBox
              from="Aeropuerto"
              to="Terminal Sur"
              schedule=" 06:00 - 22:00 hrs"
              routeImage="/assets/centropuerto/routes/terminal-box-two.png"
              box="box-two"
            />
          </div>
					
      <ul className="lh-1-3 font14 justify-text"> 
							<li>Las salidas, rutas y tiempos de viaje pueden variar de acuerdo a las condiciones de tránsito y seguridad en la ruta. Los horarios de salidas corresponden a los lugares de inicio de cada recorrido.</li>
<li>
El equipaje en el interior del bus es de exclusiva responsabilidad del pasajero..</li>
<li>
De acuerdo al D.S. 212 del Ministerio de Transportes y Telecomunicaciones, si el valor de su equipaje excede las 5 UTM declárelo solicitando en nuestras oficinas de aeropuerto un comprobante por cada equipaje, previo inventario. Todo reclamo de equipaje sólo es válido al momento de descender del bus y previa presentación de su comprobante de pago.
</li>
						</ul>
      </div>
      {/* 
      <Footer2 operator={operator} />
      */}
   </IonContent>
</IonPage>
		)
}

export default Theme11TerminalSur